.header {
  text-align: center;
  line-height: 18px;
}

.pdf-container {
  font-size: 10px;
  line-height: 18px;
  font-family: 'IBM Plex Sans', sans-serif;
}

.align-right {
  text-align: right;
}

.col-lg {
  width: 30%;
}

.col-sm {
  width: 20%;
}

.col-xxl {
  width: 66.6%;
}

.col-xl {
  width: 50%;
}

.col-xs {
  width: 16.6%;
}

.total-amount-cell {
  border-bottom: 1px solid black;
  border-top: 1px solid black;
}

.text-bold {
  font-weight: bold;
}

.text-xs {
  font-size: 6px;
}

.text-xl {
  font-size: 16px;
}

.table-shipment {
  border: 1px solid black;
  color: black;
  border-collapse: collapse;
  width: 100%;
}

.table-shipment__th {
  background-color: lightgrey;
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  border-collapse: collapse;

}

.table-shipment__td {
  border-left: 1px solid black;
  border-collapse: collapse;
}

.table-footer {
  background-color: hotpink;
  color: white;
}

.top-footer {
  padding: 10px 0 0 0;
}

.bottom-footer {
  padding: 0 0 10px 0;
}